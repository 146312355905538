import { Paper, Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { withLocale, WithLocale } from "react-targem";

import CmsContent from "@src/components/CmsContent";
import { styles } from "./Card.styles";

export type CardProps = WithStyles<typeof styles> &
  WithLocale & {
    caption?: React.ReactNode;
    image: string;
    mainContent: React.ReactNode;
    title?: React.ReactNode;
    localeOnly?: string;
  };

class Card extends React.PureComponent<CardProps> {
  public render() {
    const {
      classes,
      mainContent,
      title,
      caption,
      image,
      localeOnly,
      locale,
    } = this.props;

    if (localeOnly && localeOnly !== locale) {
      return null;
    }
    return (
      <Paper elevation={7} className={classes.cardContainer}>
        <img src={image} className={classes.logo} alt="Press Article logo" />
        <div className={classes.textContainer}>
          {title && (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}
          <Typography variant="body1" className={classes.mainContent}>
            <CmsContent content={mainContent} />
          </Typography>
          <Typography variant="body2" className={classes.caption}>
            {caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

export default withLocale(withStyles(styles)(Card));
