import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import PressTemplate from "./PressTemplate";

export type PressArticle = {
  body: string;
  photo: string;
  caption: string;
  link?: string;
  title?: string;
};

export type PressPageProps = PageWithImagesProps<
  "",
  {
    pressArticles: {
      frontmatter: {
        pressArticlesList: PressArticle[];
      };
    };
  }
>;

class PressPage extends React.Component<PressPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Press")}
          description={t(
            "Trucknet participates in different events such as the Cop21 convention in Paris 2015, the Technology week 'Viva Tech' etc.",
          )}
          keywords={[t("press")]}
        />
        <PressTemplate />
      </IndexLayout>
    );
  }
}

export default PressPage;
