import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    articlesContainer: {
      position: "relative",
      marginBottom: theme.spacing(20),
    },
    greenDots: {
      position: "absolute",
      height: "512px",
      top: theme.spacing(-5),
      right: theme.spacing(-5),
      [theme.breakpoints.down("xs")]: {
        right: theme.spacing(-16),
        height: "446px",
      },
    },
    bottomGreenDots: {
      position: "absolute",
      width: "512px",
      bottom: theme.spacing(-5),
      left: theme.spacing(-5),
    },
    blueLink: {
      color: theme.palette.trucknet.blue,
      "&:hover": {
        color: theme.palette.trucknet.blue,
        textDecoration: "underline",
      },
    },
    link: {
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  });
