import React from "react";

import HtmlContent from "@src/components/HtmlContent";

export type CmsContentProps = {
  className?: string;
  content: React.ReactNode;
  element?: React.ElementType;
};

class CmsContent extends React.Component<CmsContentProps> {
  public render() {
    const { className, content, element: Element = "div" } = this.props;

    if (typeof content === "string") {
      return (
        <HtmlContent
          className={className}
          content={content}
          element={Element}
        />
      );
    }

    return <Element className={className}>{content}</Element>;
  }
}

export default CmsContent;
