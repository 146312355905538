import { Hidden, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import GreenDots from "@src/components/GreenDots";
import Link from "@src/components/Link";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { PressPageProps } from "@src/templates/PressPage";

import Card from "./Card";
import { styles } from "./PressArticles.styles";

export type PressTemplateProps = WithPageData<PressPageProps> &
  WithStyles<typeof styles> & { className?: string };

class PressArticles extends React.PureComponent<PressTemplateProps> {
  public render() {
    const {
      pageData: {
        pressArticles: {
          frontmatter: { pressArticlesList },
        },
      },
      classes,
      className,
    } = this.props;

    const permanentArticles = pressArticlesList.filter((a) => !a.link);
    const languageArticles = pressArticlesList.filter((a) => !!a.link);

    return (
      <div className={className}>
        <div className={classes.articlesContainer}>
          <GreenDots className={classes.greenDots} />
          {permanentArticles.map((pa, index) => (
            <Card
              key={`acticle-${index}`}
              image={pa.photo}
              mainContent={pa.body}
              caption={pa.caption}
              title={pa.title}
            />
          ))}
          <Hidden xsDown>
            <GreenDots className={classes.bottomGreenDots} horizontal />
          </Hidden>
        </div>
        {!!languageArticles.length && (
          <div className={classes.articlesContainer}>
            {languageArticles.length >= 3 && (
              <GreenDots className={classes.greenDots} />
            )}
            {languageArticles.map((la, index) => (
              <Link to={la.link as string} key={`acticle-${index}`} external>
                <Card
                  image={la.photo}
                  mainContent={la.body}
                  caption={la.caption}
                  title={la.title}
                />
              </Link>
            ))}
            {languageArticles.length >= 3 && (
              <Hidden xsDown>
                <GreenDots className={classes.bottomGreenDots} horizontal />
              </Hidden>
            )}
            <Hidden smUp>
              <GreenDots className={classes.bottomGreenDots} horizontal />
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(PressArticles));
