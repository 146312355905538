import React from "react";

export type HtmlContentProps = {
  className?: string;
  content: string;
  element?: React.ElementType;
};

class HtmlContent extends React.Component<HtmlContentProps> {
  public render() {
    const { className, content, element: Element = "div" } = this.props;
    return (
      // tslint:disable react-no-dangerous-html
      <Element
        className={className}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
}

export default HtmlContent;
