import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    cardContainer: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
      padding: theme.spacing(8, 11, 10),
      margin: theme.spacing(3, 0),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 6),
      },
      "@media(max-width: 749px)": {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
        flexDirection: "column",
      },
    },
    logo: {
      width: "144px",
      objectFit: "contain",
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(5),
      },
      "@media(max-width: 749px)": {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
      },
    },
    textContainer: {
      flex: "auto",
    },
    title: {
      marginBottom: theme.spacing(3),
      textAlign: "left",
    },
    mainContent: {
      textAlign: "left",
      margin: 0,
    },
    caption: {
      textAlign: "right",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  });
