import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8.5),
    },
    pressArticles: {
      margin: "auto",
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(25),
    },
  });
