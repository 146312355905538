// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import PressPage from "@src/templates/PressPage";
export default PressPage;
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query($initialLocale: String) {
    pressArticles: markdownRemark(
      fields: {
        slug: { regex: "/^/press-articles//" }
        locale: { eq: $initialLocale }
      }
    ) {
      frontmatter {
        pressArticlesList {
          body
          photo
          caption
          title
          link
        }
      }
    }
  }
`;
